import Image, { ImageProps } from 'next/image'
import { FC } from 'react'

export const BackgroundImage: FC<ImageProps> = ({ placeholder = 'blur', ...props }) => {
  return (
    <Image
      objectFit='cover'
      objectPosition='center'
      layout='fill'
      placeholder={placeholder}
      className={placeholder === 'blur' ? 'scale-110' : ''}
      {...props}
    />
  )
}
