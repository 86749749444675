import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import tw from 'tailwind-styled-components'
import styled from 'styled-components'
import Image from 'next/image'
import { SvgIcon } from '../SvgIcon'
import { NavLink } from './NavLink'
import { theme } from '../../config'
import Logo from '@foros-fe/assets/icons/foros-logo-white.svg'
import Facebook from '@foros-fe/assets/icons/facebook.svg'
import LinkedIn from '@foros-fe/assets/icons/linked-in.svg'
import Instagram from '@foros-fe/assets/icons/instagram.svg'
import Youtube from '@foros-fe/assets/icons/youtube.svg'
import Climate from '@foros-fe/assets/images/climate-acc.jpg'

const Column = tw.div`flex flex-col`
const Container = tw(Column)`container py-9 md:py-16 md:flex-row gap-3`
const LinksContainer = tw.div`flex flex-1 flex-col-reverse gap-10 xl:flex-row`
const IconContainer = tw.div`hidden md:flex space-x-8`
const Line = tw.div`flex mr-auto mb-10 md:mb-0`
const CredentialText = tw.p`text-gray-400 mb-5`
const FooterLink = styled(NavLink)`
  color: ${theme.colors.gray[400]};
  font-size: 16px;
`
const ClimateImgContainer = tw.div`
  relative
  flex
  flex-1
  md:self-end
  xl:self-start
  max-w-[400px]
  min-h-[92px]
  md:min-h-[80px]
  xl:min-h-[92px]
`

export const FooterLanding: FC = () => {
  const { t } = useTranslation()

  return (
    <div className='bg-primary-500'>
      <Container>
        <LinksContainer>
          <Column className='mr-12 md:mr-24'>
            <SvgIcon src={Logo} width={82} height={20} className='mb-7' />
            <CredentialText>{t('copyright')}</CredentialText>
            <CredentialText>
              <FooterLink className='underline' href='/rules'>
                {t('nav.rules')}
              </FooterLink>
              &nbsp;•&nbsp;
              <FooterLink className='underline' href='/privacy-policy'>
                {t('nav.privacyPolicy')}
              </FooterLink>
            </CredentialText>
            <IconContainer>
              <a
                target='_blank'
                href='https://www.linkedin.com/company/foros-platform/'
                rel='noreferrer'
              >
                <SvgIcon src={LinkedIn} size={27} />
              </a>
              <a target='_blank' href='https://www.facebook.com/ForosLT' rel='noreferrer'>
                <SvgIcon src={Facebook} width={13} height={27} />
              </a>
              <a
                target='_blank'
                href='https://www.youtube.com/channel/UCKWsYIaiMQbsgW4Fle3uxcg'
                rel='noreferrer'
              >
                <SvgIcon src={Youtube} width={40} height={28} />
              </a>
              <a target='_blank' href='https://www.instagram.com/forosplatform/' rel='noreferrer'>
                <SvgIcon src={Instagram} width={26} height={27} />
              </a>
            </IconContainer>
          </Column>
          <Line>
            <Column className='space-y-2 mr-9'>
              <FooterLink href='/auctions'>{t('nav.auctions')}</FooterLink>
              <FooterLink href='/about-us'>{t('nav.aboutUs')}</FooterLink>
              <FooterLink href='/contact-us'>{t('nav.contactUs')}</FooterLink>
            </Column>
            <Column className='space-y-2'>
              <FooterLink href='/press'>{t('nav.pressRoom')}</FooterLink>
              {/* Hide until these pages are done */}
              {/* <FooterLink href='/'>{t('nav.invest')}</FooterLink>
            <FooterLink href='/'>{t('nav.grow')}</FooterLink> */}
            </Column>
          </Line>
        </LinksContainer>
        <ClimateImgContainer>
          <Image
            src={Climate}
            className='w-full h-full'
            objectFit='contain'
            objectPosition='top'
            layout='fill'
          />
        </ClimateImgContainer>
      </Container>
    </div>
  )
}
